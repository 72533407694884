import { useState, useEffect, useMemo, useContext } from "react";

// react-router components
import { Route, useLocation, Routes, Navigate } from "react-router-dom";

// Vision UI Dashboard React example components
import Sidenav from "defaultComponents/Sidenav";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import createCache from "@emotion/cache";

import { useVisionUIController, setMiniSidenav, setOpenConfigurator } from "context";
import Login from "pages/Login/Login";
import Dashboard from "layouts/dashboard";
import Influencers from "pages/Influencers/Influencers";
import Influencer from "pages/Influencers/Influencer/Influencer";
import Metrics from "pages/Influencers/Metrics/Metrics";

import { ProtectedRoute } from "components/ProtectedRoute/ProtectedRoute";
import Instagram from "pages/Influencers/Metrics/Instagram/Instagram";
import UpdatePassword from "pages/UpdatePassword/UpdatePassword";
import Finance from "pages/Finance/Finance";
import NotFound from "pages/404/NotFound";
import Construction from "pages/Construction/Construction";
import Accounting from "pages/Finance/Accounting/Accounting";
import Settings from "pages/SettingsPage/Settings";
import Calendar from "pages/Calendar/Calendar";
import ProjectListCommercial from "pages/Projects/ProjectList/ProjectListCommercial";
import Unauthorized from "pages/Unauthorized/Unauthorized";
import TermsAndConditions from "pages/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "pages/PrivacyPolicy/PrivacyPolicy";
import ProjectController from "pages/Projects/AddProjectPages/ProjectController";
import { TokenContext } from "context/TokenContext";
import Commercial from "pages/Commercial/Commercial";
import Kanban from "pages/Commercial/Kanban/Kanban";
import Clients from "pages/Commercial/Clients/Clients";
import Client from "pages/Commercial/Clients/Client/Client";
import InfluencerFinance from "pages/Finance/UserViews/InfluencerFinance";
import Marketing from "pages/Marketing/Marketing";
import Cashout from "pages/Finance/Cashout/Cashout";
import GeneralCashout from "pages/Finance/Cashout/GeneralCashout";
import Pipeline from "pages/Commercial/Pipeline";
/* import ForgottenPassword from "pages/ForgottenPassword/ForgottenPassword"; */
import ProjectListMarketing from "pages/Projects/ProjectList/ProjectListMarketing";
import MarketingKanban from "pages/Marketing/Kanban/MarketingKanban";
import MarketingPipeline from "pages/Marketing/MarketingPipeline";
import ProjectListInfluencer from "pages/Projects/ProjectList/ProjectListInfluencer";
import UserList from "pages/SettingsPage/UsersList/UsersList";
import CommercialRejections from "pages/Commercial/CommercialRejections";
import ProjectListLead from "pages/Projects/ProjectList/ProjectListLead";
import Products from "pages/Ecommerce/Products/Products";
import Orders from "pages/Ecommerce/Orders/Orders";
import Order from "pages/Ecommerce/Orders/Order/Order";
import EcommerceClients from "pages/Ecommerce/Clients/EcommerceClients";
import EcommerceClientDetails from "pages/Ecommerce/Clients/ModalContent/EcommerceClientDetails";
import Reports from "pages/Ecommerce/Reports/Reports";
import Affiliates from "pages/Ecommerce/Affiliates/Affiliates";
import AffiliateDetails from "pages/Ecommerce/Affiliates/AffiliateDetails/AffiliateDetails";
import Versions from "pages/VersionsPage/Versions";
import InfluencersWallets from "pages/Finance/Wallets/InfluencersWallets";

const AdminRoutes = () => {
  const [controller, dispatch] = useVisionUIController();
  const { miniSidenav, direction, layout, openConfigurator, sidenavColor } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useLocation();

  const { decodedToken } = useContext(TokenContext);

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  return [
    <Routes key="adminRoutes">
      <Route exact path="/" element={<Navigate to="/dashboard" replace />} key="start" />
      <Route path="/login" element={<Login />} key="adminLogin" />
      <Route path="/update-password" element={<UpdatePassword />} key="adminPassword" />
      {/* <Route path="/forgotten-password/:token" element={<ForgottenPassword />} /> */}
      <Route path="/unauthorized" element={<Unauthorized />} key="adminUnath" />
      <Route path="/termsandconditions" element={<TermsAndConditions />} key="adminT&C" />
      <Route path="/privacypolicy" element={<PrivacyPolicy />} key="adminP&P" />
      <Route path="/versions" element={<Versions />} key="adminVersions" />
      <Route
        path="*"
        key="admin404"
        element={
          <>
            <Sidenav
              color={sidenavColor}
              onMouseEnter={handleOnMouseEnter}
              onMouseLeave={handleOnMouseLeave}
            />
            <NotFound />
          </>
        }
      />
      <Route
        path="/dashboard"
        key="adminDashboard"
        element={
          <>
            <ProtectedRoute allowedRoles={["ADMINISTRATOR", "INFLUENCER"]}>
              <Sidenav
                color={sidenavColor}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Dashboard />
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/influencers"
        key="adminInfluencers"
        element={
          <>
            <ProtectedRoute allowedRoles={["ADMINISTRATOR"]}>
              <Sidenav
                color={sidenavColor}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Influencers />
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/influencers/:influencer"
        key="adminInfluencer"
        element={
          <>
            <ProtectedRoute allowedRoles={["ADMINISTRATOR"]}>
              <Sidenav
                color={sidenavColor}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Influencer />
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/influencers/:influencer/metricas"
        key="adminMetrics"
        element={
          <>
            <ProtectedRoute allowedRoles={["ADMINISTRATOR", "INFLUENCER"]}>
              <Sidenav
                color={sidenavColor}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Metrics />
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/influencers/:influencer/metricas/instagram"
        key="adminInstagram"
        element={
          <>
            <ProtectedRoute allowedRoles={["ADMINISTRATOR", "INFLUENCER"]}>
              <Sidenav
                color={sidenavColor}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Instagram />
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/influencers/:username/financeiro"
        key="adminInfFinance"
        element={
          <>
            <ProtectedRoute allowedRoles={["ADMINISTRATOR"]}>
              <Sidenav
                color={sidenavColor}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <InfluencerFinance />
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/influencers/:username/financeiro/saque"
        element={
          <>
            <ProtectedRoute allowedRoles={["ADMINISTRATOR"]}>
              <Sidenav
                color={sidenavColor}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Cashout />
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/influencers/:username/projetos"
        key="adminInfProjects"
        element={
          <>
            <ProtectedRoute allowedRoles={["ADMINISTRATOR"]}>
              <Sidenav
                color={sidenavColor}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <ProjectListInfluencer />
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/financeiro"
        key="adminFinance"
        element={
          <>
            <ProtectedRoute allowedRoles={["ADMINISTRATOR"]}>
              <Sidenav
                color={sidenavColor}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Finance />
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/financeiro/contas"
        key="adminAccounting"
        element={
          <>
            <ProtectedRoute allowedRoles={["ADMINISTRATOR"]}>
              <Sidenav
                color={sidenavColor}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Accounting />
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/financeiro/carteiras"
        key="adminWallets"
        element={
          <>
            <ProtectedRoute allowedRoles={["ADMINISTRATOR"]}>
              <Sidenav
                color={sidenavColor}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <InfluencersWallets />
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/financeiro/saque"
        key="adminCashout"
        element={
          <>
            <ProtectedRoute allowedRoles={["ADMINISTRATOR"]}>
              <Sidenav
                color={sidenavColor}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <GeneralCashout />
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/comercial"
        key="adminCommercial"
        element={
          <>
            <ProtectedRoute allowedRoles={["ADMINISTRATOR"]}>
              <Sidenav
                color={sidenavColor}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Commercial />
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/comercial/pipeline"
        key="adminCommercialPipelina"
        element={
          <>
            <ProtectedRoute allowedRoles={["ADMINISTRATOR"]}>
              <Sidenav
                color={sidenavColor}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Pipeline />
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/comercial/negocios"
        key="adminCommercialBusinesses"
        element={
          <>
            <ProtectedRoute allowedRoles={["ADMINISTRATOR"]}>
              <Sidenav
                color={sidenavColor}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <ProjectListCommercial />
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/comercial/negocios/recusas"
        key="adminCommercialBusinesses"
        element={
          <>
            <ProtectedRoute allowedRoles={["ADMINISTRATOR"]}>
              <Sidenav
                color={sidenavColor}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <CommercialRejections />
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/comercial/pipeline/:username"
        key="adminCommercialKanban"
        element={
          <>
            <ProtectedRoute allowedRoles={["ADMINISTRATOR"]}>
              <Sidenav
                color={sidenavColor}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Kanban />
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/comercial/clientes"
        key="adminCommercialClients"
        element={
          <>
            <ProtectedRoute allowedRoles={["ADMINISTRATOR"]}>
              <Sidenav
                color={sidenavColor}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Clients />
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/comercial/clientes/:clientId"
        key="adminCommercialClient"
        element={
          <>
            <ProtectedRoute allowedRoles={["ADMINISTRATOR"]}>
              <Sidenav
                color={sidenavColor}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Client />
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/comercial/clientes/:lead/negocios"
        key="adminLeadProjects"
        element={
          <>
            <ProtectedRoute allowedRoles={["ADMINISTRATOR"]}>
              <Sidenav
                color={sidenavColor}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <ProjectListLead />
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/marketing"
        key="adminMarketing"
        element={
          <>
            <ProtectedRoute allowedRoles={["ADMINISTRATOR", "H_COMERCIAL", "COMERCIAL"]}>
              <Sidenav
                color={sidenavColor}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Marketing />
              {/* <MarketingDashboard /> */}
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/marketing/projetos"
        key="adminMarketingProjects"
        element={
          <>
            <ProtectedRoute allowedRoles={["ADMINISTRATOR", "H_MARKETING", "MARKETING"]}>
              <Sidenav
                color={sidenavColor}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <ProjectListMarketing />
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/marketing/pipeline"
        key="adminMarketingPipeline"
        element={
          <>
            <ProtectedRoute allowedRoles={["ADMINISTRATOR", "H_MARKETING", "MARKETING"]}>
              <Sidenav
                color={sidenavColor}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <MarketingPipeline />
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/marketing/pipeline/kanban"
        key="adminMarketingKanban"
        element={
          <>
            <ProtectedRoute allowedRoles={["ADMINISTRATOR", "H_MARKETING", "MARKETING"]}>
              <Sidenav
                color={sidenavColor}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <MarketingKanban />
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/marketing/projetos/:id"
        key="adminProjectController"
        element={
          <>
            <ProtectedRoute allowedRoles={["ADMINISTRATOR"]}>
              <Sidenav
                color={sidenavColor}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <ProjectController />
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/nft"
        key="adminNFT"
        element={
          <>
            <ProtectedRoute allowedRoles={["ADMINISTRATOR", "INFLUENCER"]}>
              <Sidenav
                color={sidenavColor}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Construction page="nft" />
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/academy"
        key="adminAcademy"
        element={
          <>
            <ProtectedRoute allowedRoles={["ADMINISTRATOR", "INFLUENCER"]}>
              <Sidenav
                color={sidenavColor}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Construction page="tv" />
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/ecommerce"
        key="adminEcommerce"
        element={
          <>
            <ProtectedRoute allowedRoles={["ADMINISTRATOR"]}>
              <Sidenav
                color={sidenavColor}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Construction page="ecommerce" />
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/ecommerce/produtos"
        key="adminEcommerceProducts"
        element={
          <>
            <ProtectedRoute allowedRoles={["ADMINISTRATOR"]}>
              <Sidenav
                color={sidenavColor}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Products />
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/ecommerce/pedidos"
        key="adminEcommerceOrders"
        element={
          <>
            <ProtectedRoute allowedRoles={["ADMINISTRATOR"]}>
              <Sidenav
                color={sidenavColor}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Orders />
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/ecommerce/pedidos/:numero"
        key="adminEcommerceOrder"
        element={
          <>
            <ProtectedRoute allowedRoles={["ADMINISTRATOR"]}>
              <Sidenav
                color={sidenavColor}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Order />
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/ecommerce/clientes"
        key="adminEcommerceClients"
        element={
          <>
            <ProtectedRoute allowedRoles={["ADMINISTRATOR"]}>
              <Sidenav
                color={sidenavColor}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <EcommerceClients />
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/ecommerce/clientes/:name"
        key="adminEcommerceClientDetails"
        element={
          <>
            <ProtectedRoute allowedRoles={["ADMINISTRATOR"]}>
              <Sidenav
                color={sidenavColor}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <EcommerceClientDetails />
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/ecommerce/afiliados"
        key="adminEcommerceAffiliates"
        element={
          <>
            <ProtectedRoute allowedRoles={["ADMINISTRATOR"]}>
              <Sidenav
                color={sidenavColor}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Affiliates />
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/ecommerce/afiliados/:username"
        key="adminEcommerceAffiliateDetails"
        element={
          <>
            <ProtectedRoute allowedRoles={["ADMINISTRATOR"]}>
              <Sidenav
                color={sidenavColor}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <AffiliateDetails />
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/ecommerce/relatorios"
        key="adminEcommerceReports"
        element={
          <>
            <ProtectedRoute allowedRoles={["ADMINISTRATOR"]}>
              <Sidenav
                color={sidenavColor}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Reports />
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/agenda"
        key="adminCalendar"
        element={
          <>
            <ProtectedRoute allowedRoles={["ADMINISTRATOR", "INFLUENCER"]}>
              <Sidenav
                color={sidenavColor}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Calendar />
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/configuracoes"
        key="adminConfig"
        element={
          <>
            <ProtectedRoute allowedRoles={["ADMINISTRATOR", "INFLUENCER"]}>
              <Sidenav
                color={sidenavColor}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <Settings />
            </ProtectedRoute>
          </>
        }
      />
      <Route
        path="/configuracoes/usuarios"
        key="adminConfig"
        element={
          <>
            <ProtectedRoute allowedRoles={["ADMINISTRATOR", "INFLUENCER"]}>
              <Sidenav
                color={sidenavColor}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
              <UserList />
            </ProtectedRoute>
          </>
        }
      />
    </Routes>,
  ];
};

export default AdminRoutes;
