import styled from "styled-components";
import { Grid, TextField } from "@mui/material";
import ButtonCustom from 'components/ButtonCustom/ButtonCustom'

export const MainGrid = styled(Grid)`
  .subtitle {
    color: #8F8F8F;
    font-size: 18px;
  }

  .title {
    font-size: 22px;
  }

  .reminder {
    font-weight: 600;
  }
`

export const WarningGrid = styled(Grid)`
  margin-top: 0px;
`

export const Input = styled(TextField)`
  background-color: #0E0E0E;
  border-radius: 4px;
`

export const InputGrid = styled(Grid)`
  gap: 20px;
  .MuiOutlinedInput-input {
    color: #FFFFFF;
  }
  .MuiInputBase-root.Mui-focused {
    box-shadow: 0px 0px 10px #FC6508;
    svg {
      color: #E22B00;
    }
    .MuiOutlinedInput-input {
      color: #E22B00;
    }
  }
  & .MuiOutlinedInput-root.Mui-focused{
    & > fieldset {
      border-color: transparent;
    }
  }
  .MuiPaper-root-MuiAutocomplete-paper {
    border-radius: 5px !important;
  }
  .MuiPaper-root{
    border-radius: 5px !important;
  }
  svg {
    color: white;
    transform: rotateY(180deg);
  }
`

export const AlertGrid = styled(Grid)`
  p {
    color: #8F8F8F;
    font-weight: 400;
    font-size: 18px;
  }

  .bold {
    font-weight: 700;
  }
`

export const DateInputGrid = styled(InputGrid)`
/*   svg {
    margin-bottom: 10px;
  } */
  .flatpickr-confirm {
    color: white;
    cursor: pointer;
  }
`

export const ConfirmationModalGrid = styled(Grid)`
  .modalTitle {
    font-size: 24px;
  }

  .typeBalanceValue {
    font-size: 54px;
    font-weight: 700;
  }

  .valueDiv {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .futureDiscountValue {
    font-size: 16px;
    color: #AE2C7A;
    margin-left: 20px;
  }

  .alertDiv {
    font-size: 18px;
  }

  .paidModalText {
    font-size: 16px;
    margin-top: 10px;
  }

  .paidModalAuxiliarText {
    font-size: 16px;
    margin-bottom: 40px;
  }
`

export const ModalButton = styled(ButtonCustom)`
  background-image: linear-gradient(to right, #5ACC93, #027D3F);
  color: #FFFFFF;
  font-size: 20px !important;
  font-weight: 400 !important;
`

export const CancelButton = styled(ModalButton)`
  background-image: linear-gradient(to right, #D04494, #D7004D);
`