import { useState, useEffect } from "react";
import DashboardLayout from "defaultComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "defaultComponents/Navbars/DashboardNavbar";
import { Grid, Card } from '@mui/material';
import VuiAvatar from "components/VuiAvatar";
import * as S from './style'
import { getAllInfluencers } from "utils/requests/auth";
import zePlaceholder from 'assets/ze.png'

const Avatar = ({ img, theme, children, ...rest }) => (
  <VuiAvatar
    src={img}
    size="sm"
    {...rest}
    sx={({ borders: { borderWidth }, palette: { light }, functions: { rgba } }) => ({
      cursor: "pointer",
      position: "relative",
      "&:hover, &:focus": {
        zIndex: "10",
      },
    })}
  >
    {children}
  </VuiAvatar>
);

const InfluencersWallets = () => {
  const [influencers, setInfluencers] = useState([]);

  const fetchInfluencers = async () => {
    const data = await getAllInfluencers();

    if (Array.isArray(data)) {
      setInfluencers(data[0]);
    } else {
      setInfluencers([]);
    }
  }

  useEffect(() => {
    fetchInfluencers();
  }, []);
  
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container flexDirection="column" textAlign='center' mt='50px'>
        <p style={{ color: '#FFFFFF' }}>Selecione um influencer</p>
      </Grid>
      <Grid container item xs={12} md={12} lg={12} xl={12} mt='20px'>
        <Card sx={{ width: '100%', minHeight: '780px' }}>
          <Grid container flexDirection='column' mt='30px'>
            <Grid container>
              {influencers.map((influencer) => (
                <Grid container item flexDirection='column' xs={6} md={4} lg={3} xl={2} key={influencer?.username}>
                  <S.RouterLink to={`/influencers/${influencer.username}/financeiro`}>
                    <S.UsersPipelineGrid container item flexDirection='column'>
                      <Avatar size='xxl' img={influencer?.avatar ? influencer?.avatar : zePlaceholder} />
                      <p>{influencer?.name}</p>
                    </S.UsersPipelineGrid>
                  </S.RouterLink>
                </Grid>
              ))
              }
            </Grid>
          </Grid>
        </Card>
      </Grid >
    </DashboardLayout >
  )
}

export default InfluencersWallets