import styled from "styled-components";
import { Grid, Button } from "@mui/material";
import { Link } from "react-router-dom";

export const UsersPipelineGrid = styled(Grid)`
  && {
    align-items: center;
    cursor: pointer;
    margin-top: 15px;
  }
`

export const RouterLink = styled(Link)`
  color: inherit;
  text-decoration: inherit;
`