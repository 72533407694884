import { useState, useEffect, useContext } from "react";
import DashboardLayout from "defaultComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "defaultComponents/Navbars/DashboardNavbar";
import Datepicker from 'components/Datepicker/Datepicker';
import { Grid, Box } from '@mui/material';
import { RiAddFill } from "react-icons/ri";
import * as S from '../Accounting/style'
import * as T from "components/Toast/style";
import Table from "defaultComponents/Tables/Table";
import { IoEllipsisVertical } from "react-icons/io5";
import useMediaQuery from '@mui/material/useMediaQuery';
import moment from 'moment';
import 'moment/locale/pt-br';
import Card from "@mui/material/Card";
import Pagination from '@mui/material/Pagination';
import CreditBalance from 'layouts/billing/components/CreditBalance';
import viuzzDoc from 'assets/doc.png'
import { getPdfData } from 'utils/requests/other';
import { TokenContext } from 'context/TokenContext';
import Modal from 'components/Modal/Modal';
import InfluencerFinanceDetails from './ModalContent/InfluencerFinanceDetails';
import { 
  getInfluencerFinancial,
  deleteBalanceFinancialData,
  editBalanceStatus,
} from 'utils/requests/financial';
import { jsPDF } from "jspdf";
import { useParams } from 'react-router-dom';
import PieChart from 'defaultComponents/Charts/PieCharts/PieChart';
import AddBalanceForm from "./ModalContent/AddBalanceForm";
import ButtonCustom from "components/ButtonCustom/ButtonCustom";
import showToast from "components/Toast/Toast";

const InfluencerFinance = () => {
  moment.locale("pt-br")
  const [filterDate, setFilterDate] = useState();
  const [openMenu, setOpenMenu] = useState([]);
  const [pagination, setPagination] = useState([]);
  const [page, setPage] = useState(1);
  const [pdfMenu, setPdfMenu] = useState(false);
  const [openPdfMenu, setOpenPdfMenu] = useState([]);
  const [unixTimestamp, setUnixTimestamp] = useState(/* moment(Date.now()).endOf('month').subtract(15, 'days').valueOf() */);
  const [pdfData, setPdfData] = useState();
  const [financialData, setFinancialData] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openAddBalanceModal, setOpenAddBalanceModal] = useState(false);
  const [openConfirmDeletionModal, setOpenConfirmDeletionModal] = useState(false);
  const [openConfirmStatusChangeModal, setOpenConfirmStatusChangeModal] = useState(false);
  const [projectDetails, setProjectDetails] = useState();
  const [deleteId, setDeleteId] = useState();
  const [statusId, setStatusId] = useState();
  const [countryChart, setCountryChart] = useState({
    series: [],
    options: {
      chart: {
        type: 'donut',
      },
      labels: [],
      colors: ['#BE4E75', '#50859D', '#47C094', '#DB972C', '#744C75', '#5BADBF', '#92181C', '#545454'],
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: false
      },
      tooltip: {
        y: {
          title: {
            formatter: (label) => {
              const splitLabel = label.split('(');
              return splitLabel[0]
            },
          },
        },
      },
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
          legend: {
            position: 'bottom'
          }
        }
      }]
    }
  })

  const { decodedToken } = useContext(TokenContext);
  const role = decodedToken?.type;

  const params = useParams();

  const toggleOpenMenu = (index) => {
    const updatedMenu = [...openMenu];
    updatedMenu[index] = !updatedMenu[index];
    setOpenMenu(updatedMenu);
  };

  const handleDeleteModal = (id) => {
    setOpenConfirmDeletionModal(true);
    setDeleteId(id);
  };

  const cancelDelete = () => {
    setOpenConfirmDeletionModal(false);
    setDeleteId("");
  };

  const handleStatusModal = (id) => {
    setStatusId(id);
    setOpenConfirmStatusChangeModal(true);
  };

  const cancelStatusChange = () => {
    setOpenConfirmStatusChangeModal(false);
    setStatusId("");
  };

  const handleDelete = async () => {
    try {
      await deleteBalanceFinancialData(deleteId).then((res) => {
        if (res.type) fetchData();
        showToast("Saldo deletado com sucesso", "success");
        setOpenConfirmDeletionModal(false);
        if (!res.type) showToast("Falha ao apagar o saldo", "error");
      });
    } catch {
      console.log("error");
    }
  };

  const balanceStatusChange = async () => {
    try {
      await editBalanceStatus(statusId).then((res) => {
        if (res.type) fetchData();
        showToast("Status alterado para atual com sucesso", "success");
        setOpenConfirmStatusChangeModal(false);
        if (!res.type) showToast("Falha ao alterar o status", "error");
      });
    } catch {
      console.log("error");
    }
  };

  const toggleOpenPdfMenu = (index) => {
    const updatedMenu = [...openPdfMenu];
    updatedMenu[index] = !updatedMenu[index];
    setOpenPdfMenu(updatedMenu);
  };

  const fetchPdfData = async () => {
    await getPdfData(params?.username ?? decodedToken?.username, unixTimestamp).then(res => {
      if (!res?.message) {
        setPdfData(res);
        callPdf(res);
      };
    });
  };

  const fetchData = async () => {
    const startDate = filterDate ? moment(filterDate[0], "DD-MM-YYYY HH:mm:ss").valueOf() : '';
    const endDate = filterDate ? moment(filterDate[1], "DD-MM-YYYY HH:mm:ss").valueOf() : '';
    await getInfluencerFinancial(
      params?.username ?? decodedToken?.username,
      page - 1,
      10,
      ['CURRENT', 'FUTURE'],
      startDate,
      endDate
    ).then(res => {
      if (!res?.message && res !== financialData) {
        setFinancialData(res);
        setCountryChart(prevGraph => ({
          series: res?.businessEarningsPercentage?.data,
          options: {
            ...prevGraph.options,
            labels: res?.businessEarningsPercentage?.labels
          }
        }));
      };
    });
  };

  const closeModal = () => {
    setOpenAddBalanceModal(false);
  };

  useEffect(() => {
    if (unixTimestamp) {
      fetchPdfData();
    }
  }, [unixTimestamp]);

  const callPdf = (res) => {
    getPdf(res);
  }

  const mobile = useMediaQuery("(max-width: 992px)");

  const calculatePagination = () => {
    const roundUpArrSize = Math.ceil(financialData?.financialEntries?.length / 10);
    const paginationArr = Array.from({ length: roundUpArrSize }, (value, index) => index)
    setPagination(paginationArr);
  };

  useEffect(() => {
    if (financialData) calculatePagination();
  }, [financialData]);

  useEffect(() => {
    fetchData();
  }, [page, filterDate]);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const capitalize = (str) => {
    const newString = str.charAt(0).toUpperCase() + str.slice(1)
    return newString;
  };

  const getPdf = (data) => {
    if (data) {
      const createHeaders = (keys) => {
        const result = [];
        for (let i = 0; i < keys.length; i += 1) {
          result.push({
            id: keys[i],
            name: keys[i],
            prompt: keys[i],
            width: 65,
            align: "center",
            padding: 0
          });
        }
        return result;
      }

      const headers = createHeaders([
        "Descrição",
        "Valor",
      ]);

      var doc = new jsPDF({ putOnlyUsedFonts: true });
      doc.addImage(viuzzDoc, "PNG", 0, 0, 210, 300);
      doc.setFontSize(24)
      doc.text(data?.Influenciador, 10, 50)
      doc.setFontSize(18)
      doc.text(capitalize(moment(unixTimestamp).format("MMMM, YYYY")), 20, 60)
      doc.table(30, 80, data?.Dados, headers, { fontSize: 15 });
      doc.save('viuzz.pdf')
    }
  };

  const statusTranslation = {
    PENDING: 'PENDENTE',
    PAID: 'PAGO'
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <T.ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <S.MainGrid container spacing={3}>
        <Grid container item justifyContent='space-between'>
          <Grid item xs={12} lg={12} xl={7.95}>
            <Card 
              sx={{ justifyContent: 'space-between' }}
              onClick={() =>
                openMenu.includes(true) ? setOpenMenu(openMenu.map((bool) => false)) : null
              }
            >
              <S.TableGrid container flexDirection='column'>
                <Grid container flexDirection='column'>
                  <Grid container flexDirection='row' alignItems='center' justifyContent='space-between' sx={{ fontSize: '16px', fontWeight: '700' }}>
                    <Datepicker setDate={setFilterDate} allButton lifetime />
                    <Grid>
                      <p>Carteira de {
                          (params?.username 
                            ? params.username[0].toUpperCase() + params.username.slice(1) 
                            : decodedToken?.username[0].toUpperCase() + decodedToken.username.slice(1)
                          )
                        }
                      </p>
                    </Grid>
                  </Grid>
                  <Table
                    columns={[
                      { name: "descrição", align: "left" },
                      { name: "valor", align: "left" },
                      { name: "data", align: "left" },
                      { name: "status", align: "left" },
                      { name: "observações", align: "left" },
                    ]}
                    rows={
                      (financialData?.financialEntries && financialData?.financialEntries[0])
                      && financialData?.financialEntries[0]?.map((entry, index) => {
                        return {
                          descrição: (
                            <Grid container key={index}>
                              <Grid item md={8} xl={9}>
                                <p>{entry?.description?.length > (mobile ? 6 : 24) ? entry?.description?.substring(0, (mobile ? 6 : 24)) + "..." : entry?.description}</p>
                              </Grid>
                            </Grid>
                          ),
                          valor: (
                            <Grid container>
                              <p className={entry?.type === 'FUTURE' ? 'transfer' : 'positive'}>
                                R$ {
                                  Number((entry?.value / 100).toFixed(2))
                                    .toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                                }
                              </p>
                            </Grid>
                          ),
                          data: (
                            <p>{moment(entry?.dateInvoiced).format('L')}</p>
                          ),
                          status: (
                            <Grid container key={index}>
                              <Grid item md={8} xl={9}>
                                <p>{entry?.status ? statusTranslation[entry?.status] : ''}</p>
                              </Grid>
                            </Grid>
                          ),
                          observações: (
                            <Grid container flexDirection='column'>
                              <Grid container justifyContent='space-between'>
                                <S.MiscInfoGrid item xs={11} xl={11}>
                                  <p>{
                                    entry?.misc
                                      ?
                                      (entry?.misc?.length > 50 ? entry?.misc?.substring(0, 50) + "..." : entry?.misc)
                                      :
                                      "-"
                                  }
                                  </p>
                                </S.MiscInfoGrid>
                                <Grid item xs={1} xl={1} onClick={() => toggleOpenMenu(index)} data-cy="financial-entry-options" description={entry.description}>
                                  <IoEllipsisVertical
                                    size="20px"
                                    style={{ cursor: "pointer", zIndex: "9999" }}
                                  />
                                </Grid>
                              </Grid>
                              {openMenu[index] && (
                                <S.OptionsContainer container justifyContent="flex-end">
                                  <S.OptionsGrid display="flex" flexDirection="column" alignItems="center">
                                    <p
                                      onClick={() => { 
                                        setProjectDetails(entry); 
                                        setOpenModal(true); 
                                      }}
                                    >
                                      Ver detalhes
                                    </p>
                                    {entry?.type === "FUTURE" && (
                                      <p
                                        onClick={() => {
                                          handleStatusModal(entry.id);
                                          toggleOpenMenu(index);
                                        }}
                                      >
                                        Liberar na carteira
                                      </p>
                                    )}
                                    <p
                                      onMouseEnter={(e) => (e.target.style.color = "#E31A1A")}
                                      onMouseLeave={(e) => (e.target.style.color = "#FFFFFF")}
                                      onClick={() => {
                                        handleDeleteModal(entry.id);
                                        toggleOpenMenu(index);
                                      }}
                                    >
                                      Excluir saldo
                                    </p>
                                  </S.OptionsGrid>
                                </S.OptionsContainer>
                              )}
                            </Grid>
                          )
                        }
                      })}
                  />
                </Grid>
              </S.TableGrid>
              {pagination?.length > 0 &&
                <S.PaginationGrid container justifyContent='center' sx={{ marginTop: "20px" }}>
                  <Pagination count={pagination?.length} page={page} siblingCount={1} boundaryCount={1} onChange={handleChange} />
                </S.PaginationGrid>
              }
            </Card>
          </Grid>
          <Grid container flexDirection='column' xl={3.95} gap='20px'>
            <Grid item>
              <Card sx={{ padding: 0 }}>
                <CreditBalance
                  data={financialData}
                  state={pdfMenu}
                  setState={setPdfMenu}
                  getPdf={() => callPdf(pdfData)}
                  toggleOpenMenu={toggleOpenPdfMenu}
                  openMenu={openPdfMenu}
                  unixTimestamp={unixTimestamp}
                  setUnixTimestamp={setUnixTimestamp}
                  cashout={params?.username ?? decodedToken?.username}
                />
              </Card>
            </Grid>
            <Grid item>
              <Card sx={{ padding: 0 }}>
                <Grid item sx={{ paddingLeft: '30px', paddingBottom: '15px', paddingTop: "20px" }}>
                  <p>Lucro por Marca</p>
                  <S.PieChartGrid item xl={11.5}>
                    <PieChart key={countryChart.series} pieChartData={countryChart.series} pieChartOptions={countryChart.options} />
                  </S.PieChartGrid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
        </Grid>
      </S.MainGrid>
      {role === 'ADMINISTRATOR' &&
        <Box sx={{ "& > :not(style)": { m: 1 } }}>
          <S.ModalFab color="primary" onClick={() => setOpenAddBalanceModal(true)}>
            <RiAddFill size="30px" />
          </S.ModalFab>
        </Box>
      }
      <Modal open={openAddBalanceModal} onClose={() => setOpenAddBalanceModal(false)}>
        <AddBalanceForm
          refetch={fetchData}
          closeModal={closeModal} 
        />
      </Modal>
      <Modal size="sm" open={openConfirmDeletionModal} onClose={() => cancelDelete()}>
        <Grid container flexDirection="column" gap="20px">
          <p style={{ fontSize: "24px" }}>Confirmar exclusão</p>
          <p style={{ fontSize: "18px" }}>
            Deseja excluir esse saldo? Essa ação é irreversível
          </p>
          <Grid container gap="15px" justifyContent="center">
            <Grid item lg={3}>
              <ButtonCustom
                label="Cancelar"
                onClick={() => cancelDelete()}
                sx={{ background: "linear-gradient(180deg, #818181 0%, #2a2020 155.15%)" }}
              ></ButtonCustom>
            </Grid>
            <Grid item lg={3}>
              <ButtonCustom label="Confirmar" onClick={() => handleDelete()}></ButtonCustom>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      <Modal size="sm" open={openConfirmStatusChangeModal} onClose={() => cancelStatusChange()}>
        <Grid container flexDirection="column" gap="20px">
          <p style={{ fontSize: "24px" }}>Confirmar mudança de status</p>
          <p style={{ fontSize: "18px" }}>
            Deseja mudar de saldo futuro para atual?
          </p>
          <Grid container gap="15px" justifyContent="center">
            <Grid item lg={3}>
              <ButtonCustom
                label="Cancelar"
                onClick={() => cancelStatusChange()}
                sx={{ background: "linear-gradient(180deg, #818181 0%, #2a2020 155.15%)" }}
              ></ButtonCustom>
            </Grid>
            <Grid item lg={3}>
              <ButtonCustom
                label="Confirmar"
                onClick={() => balanceStatusChange(!statusId?.visible)}
              ></ButtonCustom>
            </Grid>
          </Grid>
        </Grid>
      </Modal>
      <Modal open={openModal} onClose={() => { setOpenModal(false) }}>
        <InfluencerFinanceDetails entry={projectDetails} />
      </Modal>
    </DashboardLayout>
  )
}

export default InfluencerFinance