import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import ButtonCustom from 'components/ButtonCustom/ButtonCustom'
import { Grid } from '@mui/material';
import * as S from './style'
import Autocomplete from '@mui/material/Autocomplete';
import { RiCalendarEventFill } from 'react-icons/ri'
import { IoAlertCircleOutline } from 'react-icons/io5'
import InputAdornment from '@mui/material/InputAdornment';
import Modal from 'components/Modal/Modal';
import { createFinancialBalance } from 'utils/requests/financial';
import moment from 'moment';
import 'moment/locale/pt-br';
import MaskMoney from 'components/Masks/MaskMoney';
import showToast from 'components/Toast/Toast';
import FormHelperText from '@mui/material/FormHelperText';
import { Formik, Form, useFormik } from 'formik';
import * as Yup from "yup";
import flatpickr from "flatpickr";
import "assets/theme/custom-flatpickr.css";
import { flatpickrOptions } from 'utils/utils';
import CloseIcon from '@mui/icons-material/Close';
import { getAllClients } from 'utils/requests/leads';
import { getProjects } from 'utils/requests/project';
import { sanitizeCurrencyValue } from 'utils/utils';

const AddBalanceForm = ({ refetch, closeModal }) => {
  moment.locale("pt-br")
  const params = useParams();

  const [businesses, setBusinesses] = useState();
  const [projects, setProjects] = useState();
  const [validateFields, setValidateFields] = useState(false);
  const [openTypeModal, setOpenTypeModal] = useState(false);
  const [openPaidModal, setOpenPaidModal] = useState(false);
  const [selectedBalanceType, setSelectedBalanceType] = useState("");
  
  const formik = useFormik({
    initialValues: {
      description: '',
      projectRelated: null,
      value: '',
      bankAccount: '',
      dateInvoiced: '',
      paymentDate: '',
      leadRelated: null,
      brand: '',
      misc: '',
      type: '',
      status: '',
    },

    validateOnChange: validateFields,
    validateOnBlur: false,

    validationSchema: Yup.object({
      description: Yup.string().required("Título é um campo obrigatório").min(4, 'Título precisa ter pelo menos 4 caracteres'),
      projectRelated: Yup.object().required('Projeto é um campo obrigatório'),
      value: Yup.string().required("Valor é um campo obrigatório"),
      bankAccount: Yup.string(),
      dateInvoiced: Yup.string().required("Data é um campo obrigatório"),
      paymentDate: Yup.string(),
      leadRelated: Yup.object().required('Empresa é um campo obrigatório'),
      brand: Yup.string(),
      misc: Yup.string(),
      type: Yup.string().required('Tipo de saldo é um campo obrigatório'),
      status: Yup.string().required('Status de saldo é um campo obrigatório'),
    }),
    onSubmit: async (values) => {
      const unixDate = (date) => {
        return Number(moment(date, 'DD/MM/YYYY HH:mm').valueOf());
      };

      const newBalance = {
        description: values.description ? values.description : 'SALDO',
        projectRelated: values.projectRelated.id,
        value: values.value ? sanitizeCurrencyValue(values.value) : '',
        bankAccount: values.bankAccount ?? '',
        dateInvoicedMs: values.dateInvoiced ? unixDate(values.dateInvoiced) : '',
        paymentDateMs: values.paymentDate ? unixDate(values.paymentDate) : '',
        leadRelated: values.leadRelated.id,
        brand: values.brand ?? '',
        misc: values.misc ?? '',
        type: values.type,
        status: values.status,
      }

      Object.keys(newBalance).forEach(key => {
        if (newBalance[key] === "") {
          delete newBalance[key];
        }
      });

      createFinancialBalance(params.username, newBalance).then((res) => {
        if (!res.message) { showToast("Saldo criado com sucesso", "success"); refetch(); closeModal() };
        if (res.message) { showToast("Falha na criação de saldo", "error") };
      });
    }
  });

  const handleVerifyBalance = () => {
    formik.validateForm().then(errors => {
      if (Object.keys(errors).length > 0) {
        setValidateFields(true);
        formik.setTouched({
          description: true,
          projectRelated: true,
          value: true,
          dateInvoiced: true,
          leadRelated: true,
          type: true,
          status: true
        });
        return;
      }
  
      const { status, type } = formik.values;
      if (status === 'PAID') {
        setOpenPaidModal(true);
        return;
      }
      if (type === 'CURRENT' || type === 'FUTURE') {
        setOpenTypeModal(true);
        setSelectedBalanceType(type);
        return;
      } 
    });
  };

  const handleClosePaidModal = () => {
    setOpenPaidModal(false);
  };

  const handleCloseTypeModal = () => {
    setOpenTypeModal(false);
  };

  const fetchBusinesses = async () => {
    const data = await getAllClients();
    if (!data.message) {
      setBusinesses(data);
    };
  };

  const fetchProjects = async () => {
    const data = await getProjects();
    if (!data.message) {
      setProjects(data);
    };
  };

  useEffect(() => {
    fetchBusinesses();
    fetchProjects();
  }, []);

  const handleStatusChange = (event) => {
    formik.setFieldValue('status', event.target.value);
  };

  const handleTypeChange = (event) => {
    formik.setFieldValue('type', event.target.value);
  };

  flatpickr("#dateInvoiced", flatpickrOptions('dateInvoiced', formik.setFieldValue, formik.setFieldTouched));
  flatpickr(".paymentDate", flatpickrOptions('paymentDate', formik.setFieldValue, formik.setFieldTouched));

  return (
    <>
      <Formik initialValues={formik.initialValues} validationSchema={formik.validationSchema} onSubmit={formik.handleSubmit} validateOnChange validateOnBlur validateOnMount>
        <Form style={{ width: '100%' }}>
          <Grid container>
            <Grid>
              <p>Adicione um saldo para <strong>{params.username[0].toUpperCase() + params.username.slice(1)}</strong></p>
            </Grid>
            <Grid container flexDirection='column' gap='20px' marginTop='20px'>
              <Grid container justifyContent='space-between'>
                <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                  <S.Input
                    name='description'
                    hiddenLabel
                    type='text'
                    placeholder="Título"
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.description}
                    error={Boolean(formik.errors.description) && formik.touched.description}
                    helperText={formik.touched.description ? formik.errors.description : false}
                  />
                </S.InputGrid>
                <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                  <Autocomplete
                    disablePortal
                    value={formik.values.projectRelated ? formik.values.projectRelated : null}
                    onChange={(e, value) => {
                      formik.setFieldValue('projectRelated', value ? value : '')
                    }}
                    options={projects ? projects[0] : []}
                    sx={{ width: "100%" }}
                    getOptionLabel={(option) => option.title}
                    renderInput={(params) =>
                      <S.Input {...params} 
                      error={Boolean(formik.errors.projectRelated)}
                      placeholder='Projeto de vínculo' />
                    }
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                  />
                  {(Boolean(formik.errors.projectRelated) && formik.touched.projectRelated) &&
                    <FormHelperText sx={{ color: "#f44336", marginLeft: "14px" }}>
                      Projeto é um campo obrigatório
                    </FormHelperText>
                  }
                </S.InputGrid>
              </Grid>
              <Grid container justifyContent='space-between'>
                <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                  <S.Input
                    name='value'
                    hiddenLabel
                    placeholder="Valor(R$)"
                    fullWidth
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.value}
                    InputProps={{ inputComponent: MaskMoney }}
                    error={Boolean(formik.errors.value) && formik.touched.value}
                    helperText={formik.touched.value ? formik.errors.value : false}
                  />
                </S.InputGrid>
                <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                  <S.Input
                    name='bankAccount'
                    hiddenLabel
                    type='text'
                    placeholder="Conta Bancária"
                    fullWidth
                    onChange={formik.handleChange}
                    value={formik.values.bankAccount}
                  />
                </S.InputGrid>
              </Grid>
              <Grid container justifyContent='space-between'>
                <S.DateInputGrid item xs={12} md={5.5} xl={5.5} id='dateInvoiced'>
                  <S.Input
                    name='dateInvoiced'
                    hiddenLabel
                    type='text'
                    placeholder="Data de Fechamento"
                    fullWidth
                    value={formik.values.dateInvoiced}
                    data-input
                    autoComplete='off'
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <RiCalendarEventFill />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position='end'>
                          <CloseIcon onClick={() => formik.setFieldValue('dateInvoiced', '')} sx={{ cursor: "pointer", "&:hover": { color: "#E22B00" } }} />
                        </InputAdornment>
                      )
                    }}
                    error={Boolean(formik.errors.dateInvoiced) && formik.touched.dateInvoiced}
                    helperText={formik.touched.dateInvoiced ? formik.errors.dateInvoiced : false}
                  />
                </S.DateInputGrid>
                <S.DateInputGrid item xs={12} md={5.5} xl={5.5} className='paymentDate'>
                  <S.Input
                    name='paymentDate'
                    hiddenLabel
                    type='text'
                    placeholder="Data de pagamento"
                    fullWidth
                    value={formik.values.paymentDate}
                    data-input
                    autoComplete='off'
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position='start'>
                          <RiCalendarEventFill />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position='end'>
                          <CloseIcon onClick={() => formik.setFieldValue('paymentDate', '')} sx={{ cursor: "pointer", "&:hover": { color: "#E22B00" } }} />
                        </InputAdornment>
                      )
                    }}
                    error={Boolean(formik.errors.paymentDate) && formik.touched.paymentDate}
                    helperText={formik.touched.paymentDate ? formik.errors.paymentDate : false}
                  />
                </S.DateInputGrid>
              </Grid>
              <Grid container justifyContent='space-between'>
                <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                  <Autocomplete
                    disablePortal
                    value={formik.values?.leadRelated ? formik.values?.leadRelated : null}
                    onChange={(e, value) => {
                      formik.setFieldValue('leadRelated', value)
                    }}
                    options={businesses ? businesses[0] : []}
                    sx={{ width: "100%" }}
                    getOptionLabel={(option) => option.businessName}
                    renderInput={(params) => 
                      <S.Input {...params} 
                      error={Boolean(formik.errors.leadRelated)}
                      placeholder='Empresa' />
                    }
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                  />
                  {(Boolean(formik.errors.leadRelated) && formik.touched.leadRelated) &&
                    <FormHelperText sx={{ color: "#f44336", marginLeft: "14px" }}>
                      Empresa é um campo obrigatório
                    </FormHelperText>
                  }
                </S.InputGrid>
                <S.InputGrid item xs={12} md={5.5} xl={5.5}>
                  <S.Input
                    name='brand'
                    hiddenLabel
                    type='text'
                    placeholder="Marca (Opcional)"
                    fullWidth
                    onChange={formik.handleChange}
                    value={formik.values.brand}
                  />
                </S.InputGrid>
              </Grid>
              <Grid container>
                <S.InputGrid item md={12} xl={12}>
                  <S.Input
                    hiddenLabel
                    name='misc'
                    type='text'
                    placeholder="Observações"
                    fullWidth
                    onChange={formik.handleChange}
                    value={formik.values.misc}
                  />
                </S.InputGrid>
              </Grid>
              <S.AlertGrid container gap='5px'>
                <IoAlertCircleOutline size='24px' color='red' style={{ alignSelf: "center" }} />
                <p className='bold'>Lembrete: </p>
                <p>Esta informação ficará visível no saldo do mês do influencer.</p>
              </S.AlertGrid>
              <Grid container justifyContent='space-between' my='20px'>
                <Grid item md={5.5} xl={5.5}>
                  <FormControl>
                    <FormLabel sx={{ color: "#fff" }}>Tipo de saldo:</FormLabel>
                    <RadioGroup
                      row
                      value={formik.values.type}
                      onChange={handleTypeChange}
                    >
                      {Boolean(formik.errors.type) && formik.touched.type && <FormHelperText sx={{ color: "#f44336" }}>Tipo de saldo é um campo obrigatório</FormHelperText>}
                      <Grid container gap='30px' sx={{ marginLeft: "20px" }}>
                        <FormControlLabel value="CURRENT" control={<Radio />} label="Atual" />
                        <FormControlLabel value="FUTURE" control={<Radio />} label="Futuro" />
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                </Grid>
                <Grid item md={5.5} xl={5.5}>
                  <FormControl>
                    <FormLabel sx={{ color: "#fff" }}>Status deste saldo:</FormLabel>
                    <RadioGroup
                      row
                      value={formik.values.status}
                      onChange={handleStatusChange}
                    >
                      {Boolean(formik.errors.status) && formik.touched.status &&
                        <FormHelperText sx={{ color: "#f44336" }}>Status deste saldo é um campo obrigatório</FormHelperText>
                      }
                      <Grid container gap='30px' sx={{ marginLeft: "20px" }}>
                        <FormControlLabel value="PENDING" control={<Radio />} label="Pendente" />
                        <FormControlLabel value="PAID" control={<Radio />} label="Pago" />
                      </Grid>
                    </RadioGroup>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid container justifyContent='flex-end'>
            <ButtonCustom
              label='Salvar'
              sx={{ width: "150px", fontSize: "18px", fontWeight: "500" }}
              // data-cy="save-financial-entry"
              onClick={handleVerifyBalance}
            />
          </Grid>
        </Form>
      </Formik>
      <Modal width='400px' open={openTypeModal} onClose={handleCloseTypeModal}>
        <S.ConfirmationModalGrid container flexDirection="column" gap="20px">
          <p className="modalTitle">
            {selectedBalanceType === "CURRENT" ? "Criar entrada de saldo atual?" : "Criar entrada de saldo futuro?"}
          </p>
          <p className="typeBalanceValue">
            {selectedBalanceType === "CURRENT"
              ? <div className="valueDiv">
                <p>
                R$ {
                    Number(sanitizeCurrencyValue(formik.values.value) / 100).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                  }
                </p>
              </div>
              : <div className="valueDiv">
                <p>
                  R$ {
                    Number(sanitizeCurrencyValue(formik.values.value) / 100).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                  }
                </p>
                <div>
                  <p className="futureDiscountValue">
                    Taxa de 5% 
                  </p>
                  <p className="futureDiscountValue">
                    (– R$ {
                      Number((sanitizeCurrencyValue(formik.values.value) / 100) * 0.05).toLocaleString("pt-BR", { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                    })
                  </p>
                </div>
              </div>
            }
          </p>
          <p className="alertDiv">
            {selectedBalanceType === "CURRENT"
              ? 
              <S.AlertGrid container alignItems='center' gap='5px'>
                <p className='bold'><IoAlertCircleOutline size='18px' color='red' style={{ alignSelf: "center" }} /> Lembrete: <span style={{fontWeight: '400'}}>Este valor estará disponível para o influencer e precisa ser creditado na conta em até 48h.</span></p>
              </S.AlertGrid>
              : 
              <S.AlertGrid container gap='5px'>
                <p className='bold'><IoAlertCircleOutline size='18px' color='red' style={{ alignSelf: "center" }} /> Lembrete: <span style={{fontWeight: '400'}}>Este valor estará disponível para o influencer e depende de aprovação e caso aprovado será abatido em 5%.</span></p>
              </S.AlertGrid>
            }
          </p>
          <Grid container gap="15px" justifyContent="right">
            <Grid item lg={3}>
              <S.ModalButton label="Criar" onClick={() => { setValidateFields(); formik.submitForm(); }} />
            </Grid>
          </Grid>
        </S.ConfirmationModalGrid>
      </Modal>
      <Modal width='400px' open={openPaidModal} onClose={handleClosePaidModal}>
        <S.ConfirmationModalGrid container flexDirection="column" textAlign="center">
          <p className='modalTitle'>
            Criando um Saldo já pago ao influencer?
          </p>
          <p className='paidModalText'>
            Você selecionou a opção de status de pagamento PAGO, isso significa que o saldo já foi pago para o influencer.
          </p>
          <p className='paidModalAuxiliarText'>
            Deseja criar esse registro na carteira mesmo assim?
          </p>
          <Grid container gap="15px" justifyContent="right">
            <Grid item lg={3}>
              <S.CancelButton label="Cancelar" onClick={() => handleClosePaidModal(false)}/>
            </Grid>
            <Grid item lg={3}>
              <S.ModalButton label="Criar" onClick={() => { setValidateFields(); formik.submitForm(); }}/>
            </Grid>
          </Grid>
        </S.ConfirmationModalGrid>
      </Modal>
    </>
  )
}

export default AddBalanceForm